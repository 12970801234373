import React, { useState } from 'react';
import {
  Link,
  Routes,
  Route
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Home from './Pages/Home';
import Projects from './Pages/Projects';
import Services from './Pages/Services';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Error from './Pages/Error';

// import Logo from './Components/Logo';
import Burger from './Components/Burger';
import MobileMenu from './Components/MobileMenu';
import ContactQuote from './Components/ContactQuote';
import CareersDrawer from './Components/CareersDrawer';
import PrivacyDrawer from './Components/PrivacyDrawer';
import './App.css';
import Logo from './Components/svg/logo';

function App() {
  const [open, setOpen] = useState(false);
  const [showContactQuote, setShowContactQuote] = useState(false);
  const [careersOpen, setCareersOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);

  return (
    <div className="App">
      <div className="button" id="appointmentBtn">
        <div id="circle"></div>
        <a href="#" onClick={() => setShowContactQuote(true)}>GET IN TOUCH</a>
      </div>
      <header className="App-header">
        <div className="nav">
          <div className="logo">
            <Link to="/"><Logo /></Link>
          </div>
        </div>
      </header>

      <div className="page">
        <AnimatePresence>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact showContactQuote={setShowContactQuote} showCareersDrawer={setCareersOpen} />} />
          </Routes>
          </AnimatePresence>

        <div className="footer"></div>
      </div>

      <Burger open={open} setOpen={setOpen} />
      <MobileMenu open={open} setOpen={setOpen} setShowContactQuote={setShowContactQuote} setShowPrivacyPolicy={setPrivacyOpen} />
      <ContactQuote open={showContactQuote} setOpen={setShowContactQuote} />
      <CareersDrawer open={careersOpen} setOpen={setCareersOpen} />
      <PrivacyDrawer open={privacyOpen} setOpen={setPrivacyOpen} />
    </div>
  );
}

export default App;
