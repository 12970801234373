import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import styled, { keyframes } from 'styled-components';
import Modal from 'react-modal';
import Logo from './svg/logo';
import FacebookIcon from './svg/facebook';
import InstagramIcon from './svg/instagram';
import Drawer from 'react-modern-drawer';

const CareersDrawer = ({ open, setOpen }) => {

  function closeModal() {
    setOpen(false);
  }

  return (
    <StyledDrawer>
      <div className={`container ${open ? "isOpen" : ''}`}>
        <a href="#" onClick={() => setOpen(false)}>CLOSE</a>
        <h1>Current Openings</h1>
        <p>There are currently no openings at this time, however feel free to send your resume and/or a little bit about yourself to careers@thatcherprojects.com.au and we will be in touch when something becomes available.</p>
      </div>
    </StyledDrawer>
  )
}

export default CareersDrawer;

const StyledDrawer = styled.div`
  .container {
    background: rgb(227, 225, 215);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 26.875rem;
    height: 100vh;
    z-index: 9999999;
    padding: 3rem 2.125rem 2.5rem;
    transition: all 0.3s ease-in-out;
    transform: translate(26.875rem);
  }

  .isOpen {
    transform: translate(0);
  }

  h1 {
    font-size: 30px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  a {
    text-decoration: none;
    margin-bottom: 40px;
    padding-bottom: 40px;
    color: #000;
    font-family: 'Cormorant Garamond', serif;
  }

  p {
    font-family: 'Cormorant Garamond', serif;
  }
`;
