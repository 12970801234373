import styled from 'styled-components';

const Button = (props) => {

  const {
    disabled,
    sendEmail
  } = props;

  return (
    <StyledButton type='button' onClick={sendEmail} disabled={disabled}>
      { disabled ? 'loading...' : props.name }
    </StyledButton>
  )
}

export default Button;

const StyledButton = styled.button`
  background: #b5845c;
  border-radius: 30px;
  border: none;
  color: #FFF;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  padding: 15px 40px;

  @media (max-width: 576px) {
    width: 100%;
  }

  &:hover {
    background: #a67b58;
  }
`;
