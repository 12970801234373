import React from "react";
import styled from "styled-components";

const TextField = (props) => {
  const { 
    placeholder,
    type,
    value,
    onChange
  } = props;
  return (
    <StyledTextField>
      <input
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
      />
      <p></p>
    </StyledTextField>
  );
};

export default TextField;

const StyledTextField = styled.div`
  width: 100%;

  input {
    background: none;
    border: none;
    border-bottom: 1px solid rgb(51, 51, 51);
    outline: 0;
    font-family: "Poppins", sans-serif;
    font-size: 1.05rem;
    letter-spacing: .1px;
    outline: 0 none transparent;
    padding-bottom: 2.2px;
    resize: none;
    transition: all .3s ease;
    width: 100%;
  }
`;