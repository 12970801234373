export default function Home() {
    return (
      <div>
        <div className="blurbContainer">
          <h1>Tailored Spaces</h1>
          <h2>Thatcher is a large scale fabricator and interior furnisher, partnering with designers, architects and builders to create bespoke spaces</h2>
        </div>
        <div className="videoWrapper">
          <video src="/bg.mp4" autoPlay={true} loop={true} muted={true} playsInline={true}></video>
        </div>
      </div>
    );
  }
