import React, { useState } from 'react';
import { motion } from "framer-motion";
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FacebookIcon from '../Components/svg/facebook';
import InstagramIcon from '../Components/svg/instagram';
import BackButton from "../Components/BackButton";

export default function Contact(props) {
  const [openRight, setOpenRight] = useState(false);
  const { showContactQuote, showCareersDrawer } = props;

  const openDrawerRight = () => setOpenRight(true);
  const closeDrawerRight = () => setOpenRight(false);

  return (
    <div>
      <BackButton>home</BackButton>
      <motion.main
        className="main__container"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ x: "100%", opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <ContactStyles>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 12, md: 12 }}>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <div className="section1">
                  <h1>Contact</h1>
                  {/* <p>Level 1/4 Bond Street, South Yarra<br /> Victoria, Australia 3141<br />  */}
                  <p>Phone +61 3 9826 1266<br /><a href="mailto:info@thatcher.com.au">info@thatcherprojects.com.au</a></p>
                  <div className="social">
                    <div className="instagram">
                      <a href="https://www.instagram.com/thatcherprojects" target="_blank"><InstagramIcon /></a>
                    </div>
                    <div className="facebook">
                      <a href="https://www.facebook.com/thatcherprojects" target="_blank"><FacebookIcon /></a>
                    </div>
                  </div>
                </div>
                <div className="section2">
                  <p>&copy; Thatcher Pty Ltd.<br />Please respect our intellectual property rights. Thatcher will protect its intellectual property and will take action to prevent the unauthorised use of our intellectual property.</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <div className="section1">
                  <h1>New Project Enquiries</h1>
                  <p>If you have a new project, please complete our new project form below.</p>
                  <br /><a href="#" onClick={() => showContactQuote(true)}>Contact us</a>
                </div>
                <div className="section2">
                  <h1>Careers</h1>
                  <a href="#" onClick={() => showCareersDrawer(true)}>View Current Roles</a>
                </div>
              </Grid>
            </Grid>
          </Box>
        </ContactStyles>
      </motion.main>
    </div>
  );
}

const ContactStyles = styled.div`
  text-align: left;
  width: 100%;
  padding: 120px 0;
  margin-top: 60px;

  h1 {
    font-family: 'Work Sans', sans-serif;
    font-family: 'Cormorant Garamond', serif;
    text-align: left;
    width: 100%;
    font-size: 30px;
    font-family: 'Cormorant Garamond', serif;
    font-family: 'Work Sans', sans-serif;
    margin-bottom: 20px;
    font-family: 'Playfair Display', sans-serif;
    font-family: 'Work Sans', sans-serif;
  }

  a {
    color: #000;
  }

  p, a {
    color: #767676;
    font-size: 1rem;
    font-family: 'Cormorant Garamond', serif;
    color: #767676;
    line-height: 1.375em;
  }

  div.section1, div.section2 {
    padding-left: 30px;
    padding-right: 30px;
    min-width: 300px;
    margin-bottom: 50px;
  }

  .social {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 30px auto 0;
  }

  .instagram {
    margin-right: 10px;
    width: 24px;
  }
  .facebook {
    width: 24px;
  }
`;
