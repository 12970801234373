import React, { forwardRef } from "react";
import styled from "styled-components";

const TextArea = forwardRef((props, ref) => {
  const { 
    placeholder, 
    value,
    onChange
  } = props;
  return (
    <StyledTextArea>
      <textarea
        placeholder={placeholder}
        type="text"
        value={value}
        rows={1}
        ref={ref}
        onChange={onChange}
      />
      <p></p>
    </StyledTextArea>
  );
});

export default TextArea;

const StyledTextArea = styled.div`
  width: 100%;

  textarea {
    background: none;
    border: none;
    border-bottom: 1px solid rgb(51, 51, 51);
    outline: 0;
    font-family: "Poppins", sans-serif;
    font-size: 1.05rem;
    letter-spacing: .1px;
    outline: 0 none transparent;
    padding-bottom: 2.2px;
    resize: none;
    transition: all .3s ease;
    width: 100%;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-rendering: auto;
  }
`;