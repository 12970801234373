import styled from 'styled-components';

const Checkbox = (props) => {
  const { name, value, setValue } = props;

  const handleChange = (e) => {
    setValue(!value)
  }

  return (
    <StyledCheckbox>
      <label>
        <input 
          type="checkbox" 
          value={value} 
          checked={value ? 1 : 0}
          onChange={handleChange} />
        <span>{name}</span>
      </label>
    </StyledCheckbox>
  )
}

export default Checkbox;

const StyledCheckbox = styled.div`
  label {
    line-height: 3.0em;
    cursor: pointer;
  }

  label span {
    color: #000;
    text-align: center;
    margin: 10px 3px 0px;
    padding: 15px 30px;
    margin: 4px;
    background-color: rgba(255, 255, 255, 0.56);;
    border-radius: 30px;
    overflow: hidden;
    
  }

  label input {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  label input + span {
    font-family: sans-serif, Arial;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #000;
  }

  input:checked + span {
    color: #ffffff;
  }

  input:checked + span {
    background-color: #F75A1B;
  }
`;
