import { motion } from "framer-motion";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import BackButton from "../Components/BackButton";

export default function Services() {
  return (
    <div>
      <BackButton>home</BackButton>
      <motion.main
        className="main__container"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ x: "100%", opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <ServiceStyles>
          <h2>Services</h2>
          <h1>Thatcher is large scale fabricator that takes great pleasure in bringing your designs to life.</h1>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
              <Grid item xs={12} sm={12} md={6} lg={6}>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className="rightgrid">
                <div className="">
                  <h3>Manufacturing</h3>
                  <p>With manufacturing and fabrication facilities strategically positioned in multiple locations, Thatcher has the capacity for regular and large-scale.</p>
                  <h3>Installation</h3>
                  <p>We provide dedicated installation services for all our projects, aiding our clients in project efficiency and meeting deadlines.</p>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }} className="box">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="image">
                  <img src="/images/office.jpg" alt="office" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="right">
                  <h2>Workplace<br />&amp;<br />Office Fitout</h2>
                  <span class="line-after" />
                  <p>Be it a fresh workplace fit-out, office overhaul, or refurbishment, we can transform your design vision into reality, fostering an environment for your business to thrive and your team to excel. Our expertise extends to,</p>
                  <ul>
                    <li>Fixtures and Finishings</li>
                    <li>Encompassing Reception Counter</li>
                    <li>Custom-made Furniture</li>
                    <li>Office Essentials</li>
                    <li>Storage Solutions, Workstations</li>
                    <li>Flooring Options, Privacy Curtains</li>
                    <li>Light Boxes</li>
                    <li>And More...</li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }} className="box">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
              <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
                <div className="right">
                  <h2>Boutique Shop<br />&amp;<br />Retail Fitouts</h2>
                  <span class="line-after" />
                  <p>The success of a retail shop is significantly influenced by the initial impressions and ambiance it creates. Whether you're considering a complete revamp of your shop interior or a new fit-out from the ground up, we recognize the importance of ensuring your brand resonates with customers on a tangible level.</p>
                  <p>This Includes,</p>
                  <ul>
                    <li>Designer Fashion</li>
                    <li>Beauty Salons</li>
                    <li>Spa And Wellness</li>
                    <li>Boutique Shops</li>
                    <li>And More...</li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
                <div className="image">
                  <img src="/images/shop.jpg" alt="office" />
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }} className="box">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="image">
                  <img src="/images/commercial.jpg" alt="office" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="right">
                  <h2>Commercial</h2>
                  <span class="line-after" />
                  <p>From Medical and Dental to industrial settings and Veterinary Clinics, our extensive experience and expertise encompass a wide range of internal finishes and furnishings for all commercial fit-outs. We collaborate with a diverse array of products and materials to curate imaginative and tailor-made spaces.</p>
                  <ul>
                    <li>Receptions and Foyer Areas</li>
                    <li>Point Of Sale Counters and Display Units</li>
                    <li>WorkStations and Conference Tables</li>
                    <li>Designer Furniture</li>
                    <li>And Much More...</li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }} className="box">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
              <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
                <div className="right">
                  <h2>Bespoke Joinery<br />&amp;<br />Fixtures Production</h2>
                  <span class="line-after" />
                  <p>We consistently offer innovative, out-of-the-box solutions tailored to your unique needs. Additionally, we take pride in crafting both beautiful and functional solutions for spaces that demand specialized, detailed, and custom joinery and finishes, such as,</p>
                  <ul>
                    <li>Timber Veneer</li>
                    <li>Microcement Render</li>
                    <li>Solid Aged Wood</li>
                    <li>Stone and Marble</li>
                    <li>Glass and Acrylic</li>
                    <li>Timber Laminate</li>
                    <li>And more...</li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
                <div className="image">
                  <img src="/images/bespoke.jpg" alt="office" />
                </div>
              </Grid>
            </Grid>
          </Box>
        </ServiceStyles>
      </motion.main>
    </div>
  );
}

const ServiceStyles = styled.div`
  text-align: center;
  width: 100%;
  padding: 170px 0;
  max-width: 1000px;

  h1 {
    font-family: 'Cormorant Garamond', serif;
    text-align: center;
    width: 100%;
    font-size: 40px;
    font-family: 'Work Sans', sans-serif;
    padding: 0 13px;
    margin-bottom: 50px;
  }

  h2 {
    font-family: 'Work Sans', sans-serif;
    text-align: center;
    width: 100%;
    font-size: 24px;
    font-family: 'Cormorant Garamond', serif;
    padding: 0 13px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }

  h3 {
    font-family: 'Work Sans', sans-serif;
    text-align: left;
    width: 100%;
    padding: 0 40px;
    font-size: 14px;
    font-family: 'Cormorant Garamond', serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 40px;
    padding: 0 40px;
    text-align: left;
    font-family: 'Work Sans', sans-serif;
    font-family: 'Cormorant Garamond', serif;
    color: #767676;
    line-height: 1.375em;
  }

  ul, li {
    font-family: 'Work Sans', sans-serif;
    font-family: 'Cormorant Garamond', serif;
    color: #767676;
    line-height: 1.375em;
  }

  .box {
    margin-bottom: 60px;
  }

  .image {
    height: 460px;
  }

  .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .right p {
    color: #767676;
    margin-bottom: 20px;
    padding: 0 20px;
    width: 100%;
  }

  .rightgrid {
    margin-bottom: 40px;
  }

  @media (min-width: 900px) {
    .rightgrid:before {
      content: "";
      background-color: #CCC;
      position: absolute;
      width: 1px;
      height: 220px;
      top: 360px;
      left: 50%;
      display: block;
    }
  }

  @media (max-width: 899px) {
    h1, h2, h3 {
      padding: 0 40px;
    }

    p {
      padding: 0 40px;
    }

    .right p {
      padding: 0 40px;
    }
  }
`;
