import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import styled, { keyframes } from 'styled-components';
import Modal from 'react-modal';
import Logo from './svg/logo';
import FacebookIcon from './svg/facebook';
import InstagramIcon from './svg/instagram';

const MobileMenu = ({ open, setOpen, setShowContactQuote, setShowPrivacyPolicy }) => {

  const [openStyle, setOpenStyle] = useState('closed');

  function closeModal() {
    setOpen(false);
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (open) {
      setOpenStyle('open');
    } else {
      setOpenStyle('closed');
    }
  }, [open]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={open}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <StyledMenu className={openStyle}>
        <li>
          <Link onClick={() => setOpen(false)} to="/">Home</Link>
        </li>
        <li>
          <Link onClick={() => setOpen(false)} to="projects">Projects</Link>
        </li>
        <li>
          <Link onClick={() => setOpen(false)} to="services">Our Services</Link>
        </li>
        <li>
          <Link onClick={() => setOpen(false)} to="about">About</Link>
        </li>
        <li>
          <Link onClick={() => setOpen(false)} to="contact">Contact</Link>
        </li>
        <li>
          <div className="social">
            <div className="instagram">
              <a href="https://www.instagram.com/thatcherprojects" target="_blank"><InstagramIcon /></a>
            </div>
            <div className="facebook">
              <a href="https://www.facebook.com/thatcherprojects" target="_blank"><FacebookIcon /></a>
            </div>
          </div>
        </li>
        <li className="legals">
          <a href="#" onClick={() => setShowPrivacyPolicy(true)} className="privacyText">Privacy</a>
          <p className="smallText">&copy; Thatcher Pty Ltd.</p>
        </li>
      </StyledMenu>
    </Modal>
  )
}

export default MobileMenu;

const slideUpAnimation = keyframes`
  0% { opacity: 0; transform: translateY(80%); }
  20% { opacity: 0.2; }
  50% { opacity: 0.5; transform: translateY(0%); }
  100% { opacity: 1; transform: translateY(0%); }
`;

const customStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: '99',
    color: '#000',
    textAlign: 'center',
    inset: '0'
  },
};

const StyledContactButton = styled.button`
  background-color: #CCC;
  border-radius: 30px;
  color: #b5845c;
  font-size: 1.2rem;
  margin-top: 40px;
  padding: 20px 0;
`;

const StyledMenu = styled.ul`
  font-family: 'Work Sans', sans-serif;
  font-family: 'Cormorant Garamond', serif;
  text-transform: uppercase;
  animation-name: ${slideUpAnimation};
  animation-duration: 2s;
  animation-iteration-count: 1;

  opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition: opacity 1.2s,-webkit-transform 1.2s cubic-bezier(.215,.61,.355,1);
    transition: transform 1.2s cubic-bezier(.215,.61,.355,1),opacity 1.2s;
    transition: transform 1.2s cubic-bezier(.215,.61,.355,1),opacity 1.2s,-webkit-transform 1.2s cubic-bezier(.215,.61,.355,1);

  li {
    font-size: 20px;
    margin-bottom: 10px;
  }

  li.legals {
    margin-top: 30px;
  }

  a {
    color: #000;
    text-decoration: none;
    -webkit-transition: color 1s; /* For Safari 3.0 to 6.0 */
    transition: color 0.1s; /* For modern browsers */
  }

  a:hover {
    color: #8d8d8d;
  }

  .social {
    width: 60px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 30px auto 0;
  }

  .instagram {
    margin-right: 16px;
    width: 24px;
  }
  .facebook {
    width: 24px;
  }
`;
