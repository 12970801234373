import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import styled, { keyframes } from 'styled-components';
import Modal from 'react-modal';
import Logo from './svg/logo';
import FacebookIcon from './svg/facebook';
import InstagramIcon from './svg/instagram';
import Drawer from 'react-modern-drawer';

const PrivacyDrawer = ({ open, setOpen }) => {

  function closeModal() {
    setOpen(false);
  }

  return (
    <StyledDrawer>
      <div className={`container ${open ? "isOpen" : ''}`}>
        <a href="#" onClick={() => setOpen(false)}>CLOSE</a>
        <h1>Privacy Policy</h1>
        <p>Thatcher is bound by the Australian Privacy Principles in the Privacy Act 1988. Thatcher collects personal information for the purpose of providing you with its products and services. If your personal information is not provided, Thatcher may not be able to provide you with its products and services. By contacting Thatcher, you can request access to the personal information Thatcher holds about you and request not to be contacted by Thatcher for marketing purposes.</p>
      </div>
    </StyledDrawer>
  )
}

export default PrivacyDrawer;

const StyledDrawer = styled.div`
  .container {
    background: rgb(227, 225, 215);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 26.875rem;
    height: 100vh;
    z-index: 9999999;
    padding: 3rem 2.125rem 2.5rem;
    transition: all 0.3s ease-in-out;
    transform: translate(26.875rem);
  }

  .isOpen {
    transform: translate(0);
  }

  h1 {
    font-size: 30px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  a {
    text-decoration: none;
    margin-bottom: 40px;
    padding-bottom: 40px;
    color: #000;
    font-family: 'Cormorant Garamond', serif;
  }

  p {
    font-family: 'Cormorant Garamond', serif;
  }
`;
