import styled from 'styled-components';
import ArrowIcon from './svg/arrow';

const BackButton = (props) => {
  return (
    <StyledButton type='button' href='/'>
      <ArrowIcon /> Back to {props.children}
    </StyledButton>
  )
}

export default BackButton;

const StyledButton = styled.a`
  display: block;
  position: absolute;
  left: 35px;
  top: 100px;
  color: #000;
  font-size: 15px;
  font-family: 'Work Sans', sans-serif;
  font-family: 'Cormorant Garamond', serif;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: rgba(255, 255, 255, 0.56);
    color: #777;
  }
`;
