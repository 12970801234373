import React from "react";

function Logo() {
  return (
    <svg 
        version="1.1" 
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg" 
        x="0px" 
        y="0px"
        width="100%" 
        viewBox="0 0 2048 314"
        xmlSpace="preserve">
      <path fill="#FFFFFF" opacity="0" stroke="none" 
	d="
M1668.000000,315.000000 
	C1639.309937,315.000000 1610.619995,315.000000 1581.441162,314.729828 
	C1580.942383,314.279144 1580.932129,314.098602 1581.012451,313.462769 
	C1581.102905,228.570129 1581.102905,144.132767 1581.102905,59.526161 
	C1570.878296,59.526161 1561.177612,59.526161 1550.863403,59.526161 
	C1550.863403,97.964546 1550.863403,136.013351 1550.863403,174.048096 
	C1502.979736,174.048096 1455.716187,174.048096 1407.831787,174.048096 
	C1407.831787,135.710846 1407.831787,97.659889 1407.831787,59.389996 
	C1397.628906,59.389996 1388.048584,59.389996 1377.850586,59.389996 
	C1377.850586,61.407082 1377.850586,63.189209 1377.850586,64.971336 
	C1377.850464,146.293518 1377.848755,227.615707 1377.859497,308.937897 
	C1377.859741,310.598145 1378.001343,312.258362 1378.062012,314.189240 
	C1378.031250,314.639923 1378.015625,314.819977 1378.000000,315.000000 
	C1329.976685,315.000000 1281.953247,315.000000 1233.685547,314.631653 
	C1242.553101,313.501282 1251.816528,313.524719 1260.742432,311.800323 
	C1275.550659,308.939514 1290.154907,305.006714 1304.803589,301.359070 
	C1306.187134,301.014557 1308.207520,299.608551 1308.353149,298.489929 
	C1309.391357,290.509888 1310.019287,282.476471 1310.885132,273.323730 
	C1307.570435,274.292603 1304.853516,274.365631 1303.111328,275.705780 
	C1282.332764,291.689636 1258.860107,299.642181 1232.713379,300.181610 
	C1191.253540,301.036926 1160.206665,283.588806 1141.390137,246.607025 
	C1126.027954,216.414169 1124.953125,184.086472 1132.424438,151.650269 
	C1139.359741,121.540619 1155.763428,97.740349 1183.915283,83.394203 
	C1203.541870,73.392525 1224.577148,71.205437 1246.162720,74.138206 
	C1265.236328,76.729713 1283.084961,82.550812 1298.190674,95.223656 
	C1299.980591,96.725304 1303.054199,96.696854 1305.779541,97.445465 
	C1306.600586,88.618515 1307.307373,81.019897 1308.108765,72.404694 
	C1297.829834,69.741417 1287.830444,66.654190 1277.622925,64.601685 
	C1250.365356,59.120827 1222.968018,56.396000 1195.460571,63.082058 
	C1131.451050,78.640404 1086.663940,136.267975 1098.540771,208.752197 
	C1107.802002,265.274078 1154.662109,307.646606 1211.486938,313.098114 
	C1216.340698,313.563782 1221.163086,314.358276 1226.000000,315.000000 
	C1079.976685,315.000000 933.953369,315.000000 787.620239,314.606689 
	C787.541809,312.278625 787.981445,310.343170 787.974670,308.409241 
	C787.725159,236.994522 787.395447,165.579987 787.284546,94.165108 
	C787.279907,91.161118 788.583862,87.640358 790.419312,85.228897 
	C806.444092,64.175011 822.702148,43.298100 838.978088,22.436703 
	C840.656860,20.284966 840.780273,18.607233 839.478271,16.204506 
	C836.789612,11.242499 834.470947,6.079995 832.000000,0.999990 
	C1237.552002,1.000000 1643.104004,1.000000 2049.000000,1.000000 
	C2049.000000,102.356400 2049.000000,203.713181 2048.607910,305.693115 
	C2035.964966,304.279297 2025.635376,298.854889 2019.365723,287.870148 
	C2015.018799,280.254272 2011.353027,272.160339 2008.230347,263.957367 
	C2004.213501,253.405319 2001.572388,242.320602 1997.404053,231.836945 
	C1987.963989,208.095642 1973.538696,188.785324 1948.195923,179.986038 
	C1948.047241,179.934341 1948.018677,179.536774 1947.919434,179.260300 
	C1972.655640,171.386459 1988.842407,155.891556 1991.492432,129.115662 
	C1993.465576,109.178314 1988.400879,91.446342 1972.593506,77.908058 
	C1958.989502,66.256790 1942.646973,61.090946 1925.271362,60.198772 
	C1903.836548,59.098186 1882.327515,59.441811 1860.850220,59.184280 
	C1860.394287,59.178810 1859.935059,59.451298 1859.334229,59.638760 
	C1859.334229,144.390793 1859.334229,229.086914 1859.334229,313.899414 
	C1869.264526,313.899414 1878.856445,313.899414 1889.055420,313.899414 
	C1889.055420,233.785294 1889.055420,153.915848 1889.055420,74.217041 
	C1900.130859,74.217041 1910.678223,73.381454 1921.039917,74.412842 
	C1936.593384,75.960983 1950.078491,82.384888 1955.782593,98.072411 
	C1958.723755,106.161186 1960.154907,115.260056 1960.106934,123.890533 
	C1959.944946,153.076996 1947.072998,167.499115 1918.534668,171.674362 
	C1912.852295,172.505722 1907.026123,172.354568 1901.381226,172.650467 
	C1901.381226,176.833862 1901.381226,180.566223 1901.381226,184.168777 
	C1902.073975,184.386093 1902.380737,184.551682 1902.695923,184.569946 
	C1904.690552,184.685608 1906.686035,184.795914 1908.682861,184.858231 
	C1928.583740,185.479279 1944.118042,194.064804 1955.285767,210.509415 
	C1963.912720,223.212845 1968.431763,237.638077 1973.015869,252.113770 
	C1976.821777,264.132324 1980.925781,276.149597 1986.124023,287.611237 
	C1993.079590,302.947998 2005.420410,311.852600 2022.439087,313.779633 
	C2024.643921,314.029327 2026.813843,314.585663 2029.000000,315.000000 
	C1947.643311,315.000000 1866.286621,315.000000 1784.423340,314.716736 
	C1783.895996,314.243805 1783.875000,314.054108 1783.916016,313.408997 
	C1783.977905,305.673981 1783.977905,298.394379 1783.977905,290.577271 
	C1755.244507,294.235474 1727.172485,297.809509 1698.973389,301.399719 
	C1698.973389,262.986359 1698.973389,225.743439 1698.973389,187.863083 
	C1725.750366,189.457260 1752.188110,191.031250 1778.562744,192.601471 
	C1778.562744,184.723419 1778.562744,177.515671 1778.562744,170.107971 
	C1751.795898,171.716660 1725.483276,173.298035 1699.045410,174.886932 
	C1699.045410,140.417542 1699.045410,106.695419 1699.045410,72.080200 
	C1727.125000,75.686943 1754.723389,79.231857 1782.534302,82.804077 
	C1782.534302,74.718048 1782.534302,67.156876 1782.534302,59.490646 
	C1744.491211,59.490646 1706.785889,59.490646 1668.330566,59.490646 
	C1668.330566,62.813786 1668.330566,65.781212 1668.330566,68.748634 
	C1668.330444,148.552963 1668.329224,228.357285 1668.338257,308.161621 
	C1668.338501,309.990417 1668.454834,311.819244 1668.389648,313.986755 
	C1668.174927,314.550293 1668.087524,314.775146 1668.000000,315.000000 
M972.371094,314.147095 
	C973.803894,314.038940 975.236694,313.930817 977.055420,313.793579 
	C977.055420,233.167725 977.055420,152.896439 977.055420,72.039330 
	C1007.785095,75.885880 1038.034058,79.672256 1068.468018,83.481789 
	C1068.468018,74.889763 1068.468018,67.176224 1068.468018,59.565922 
	C997.179504,59.565922 926.292969,59.565922 855.444580,59.565922 
	C855.444580,67.606888 855.444580,75.212662 855.444580,83.511444 
	C886.081299,79.680969 916.333801,75.898544 947.104919,72.051270 
	C947.104919,153.109680 947.104919,233.373123 947.104919,314.147339 
	C955.485046,314.147339 963.439453,314.147339 972.371094,314.147095 
z"/>
<path fill="#FFFFFF" opacity="0" stroke="none" 
	d="
M279.000000,315.000000 
	C227.310699,315.000000 175.621384,315.000000 123.398872,314.690613 
	C122.819633,314.175293 122.773613,313.969299 122.781296,313.313049 
	C122.835007,232.852783 122.835007,152.842743 122.835007,72.085899 
	C153.914276,75.966972 183.996902,79.723587 214.240860,83.500351 
	C214.240860,74.838379 214.240860,67.124130 214.240860,59.130604 
	C212.001373,59.130604 210.180908,59.130585 208.360428,59.130604 
	C165.721176,59.131004 123.081924,59.147041 80.442688,59.126068 
	C53.961781,59.113045 27.480896,59.043938 1.000000,59.000000 
	C1.000000,39.676079 1.000000,20.352161 1.000000,1.000000 
	C277.355957,1.000000 553.711914,1.000000 830.449402,1.328095 
	C814.927856,21.564718 799.087524,41.524323 783.001648,61.284054 
	C781.726868,62.849968 778.738098,63.770245 776.564148,63.738609 
	C754.929871,63.423748 733.300781,62.770554 711.667480,62.359703 
	C682.364990,61.803200 653.059753,61.391865 623.756287,60.886421 
	C605.612427,60.573460 587.469727,60.192032 569.325928,59.874138 
	C567.907532,59.849289 566.484558,60.083057 565.130371,60.191307 
	C565.130371,68.643555 565.130371,76.576416 565.130371,85.024277 
	C632.800171,83.021828 700.177795,81.028015 768.775696,78.998093 
	C757.918884,92.106979 747.885925,104.221069 737.770813,116.434372 
	C736.662354,115.651703 735.951660,115.230225 735.333008,114.701149 
	C725.909668,106.641945 715.293701,100.803970 702.961304,99.003181 
	C672.155090,94.504784 643.415283,101.171173 618.968994,119.972107 
	C567.396118,159.635361 545.675171,214.219116 544.314880,277.929688 
	C544.137512,286.235901 546.097778,294.461975 550.459595,301.854279 
	C555.889160,311.056213 564.812866,315.668213 574.295410,313.493134 
	C578.452087,312.539673 582.911133,311.271606 586.283447,308.833282 
	C596.914734,301.146423 607.827698,293.610931 617.322083,284.632111 
	C652.708618,251.167419 684.268738,214.130859 715.811584,177.108200 
	C722.122620,169.700867 728.337585,162.211700 735.493408,153.693024 
	C761.142273,205.336807 768.405884,259.378296 768.000000,315.000000 
	C615.310669,315.000000 462.621368,315.000000 309.442169,314.729553 
	C308.941803,314.278412 308.931305,314.097687 309.011139,313.461761 
	C309.101440,271.764679 309.101440,230.522812 309.101440,189.322784 
	C357.484589,189.322784 405.055023,189.322784 453.154663,189.322784 
	C453.154663,231.028015 453.154663,272.416809 453.154663,313.892792 
	C463.278961,313.892792 472.870667,313.892792 482.682434,313.892792 
	C482.682434,228.950729 482.682434,144.263489 482.682434,59.539871 
	C472.647919,59.539871 462.940582,59.539871 452.681274,59.539871 
	C452.681274,97.987808 452.681274,136.032684 452.681274,173.883087 
	C404.580780,173.883087 357.043549,173.883087 309.041779,173.883087 
	C309.041779,135.512344 309.041779,97.599197 309.041779,59.530785 
	C298.870453,59.530785 289.171539,59.530785 279.134644,59.530785 
	C279.134644,61.736969 279.134674,63.549030 279.134674,65.361092 
	C279.134735,143.667603 279.132355,221.974106 279.141083,300.280609 
	C279.141602,304.776550 279.232361,309.272461 279.214020,314.077026 
	C279.097992,314.590454 279.049011,314.795227 279.000000,315.000000 
z"/>
<path fill="#B6855E" opacity="1.000000" stroke="none" 
	d="
M768.468262,315.000000 
	C768.405884,259.378296 761.142273,205.336807 735.493408,153.693024 
	C728.337585,162.211700 722.122620,169.700867 715.811584,177.108200 
	C684.268738,214.130859 652.708618,251.167419 617.322083,284.632111 
	C607.827698,293.610931 596.914734,301.146423 586.283447,308.833282 
	C582.911133,311.271606 578.452087,312.539673 574.295410,313.493134 
	C564.812866,315.668213 555.889160,311.056213 550.459595,301.854279 
	C546.097778,294.461975 544.137512,286.235901 544.314880,277.929688 
	C545.675171,214.219116 567.396118,159.635361 618.968994,119.972107 
	C643.415283,101.171173 672.155090,94.504784 702.961304,99.003181 
	C715.293701,100.803970 725.909668,106.641945 735.333008,114.701149 
	C735.951660,115.230225 736.662354,115.651703 737.770813,116.434372 
	C747.885925,104.221069 757.918884,92.106979 768.775696,78.998093 
	C700.177795,81.028015 632.800171,83.021828 565.130371,85.024277 
	C565.130371,76.576416 565.130371,68.643555 565.130371,60.191307 
	C566.484558,60.083057 567.907532,59.849289 569.325928,59.874138 
	C587.469727,60.192032 605.612427,60.573460 623.756287,60.886421 
	C653.059753,61.391865 682.364990,61.803200 711.667480,62.359703 
	C733.300781,62.770554 754.929871,63.423748 776.564148,63.738609 
	C778.738098,63.770245 781.726868,62.849968 783.001648,61.284054 
	C799.087524,41.524323 814.927856,21.564718 830.915466,1.328095 
	C831.000000,1.000000 831.500000,1.000000 831.750000,0.999990 
	C834.470947,6.079995 836.789612,11.242499 839.478271,16.204506 
	C840.780273,18.607233 840.656860,20.284966 838.978088,22.436703 
	C822.702148,43.298100 806.444092,64.175011 790.419312,85.228897 
	C788.583862,87.640358 787.279907,91.161118 787.284546,94.165108 
	C787.395447,165.579987 787.725159,236.994522 787.974670,308.409241 
	C787.981445,310.343170 787.541809,312.278625 787.155151,314.606689 
	C780.978821,315.000000 774.957703,315.000000 768.468262,315.000000 
M648.452515,218.939514 
	C672.577637,191.696091 696.702698,164.452667 720.957031,137.063309 
	C712.941467,129.869461 704.758789,125.685394 695.129089,124.910851 
	C674.817078,123.277130 656.157104,128.372528 639.148071,139.447464 
	C615.031128,155.150482 598.880005,177.483170 587.234558,203.273026 
	C576.674866,226.658356 569.254517,250.897202 570.015564,276.982635 
	C570.216187,283.859344 571.761719,285.091431 577.744446,281.690002 
	C585.536255,277.259979 593.190979,272.455017 600.352112,267.075806 
	C618.451904,253.479782 633.057495,236.298126 648.452515,218.939514 
M771.879089,132.500031 
	C771.879089,124.917908 771.879089,117.335777 771.879089,108.436996 
	C764.714539,117.371185 758.334229,125.327400 752.642273,132.425278 
	C759.195068,145.957016 765.383179,158.735672 771.879028,172.149902 
	C771.879028,158.951187 771.879028,146.225601 771.879089,132.500031 
z"/>
<path fill="#FFFFFE" opacity="0" stroke="none" 
	d="
M93.000000,315.000000 
	C62.374912,315.000000 31.749825,315.000000 1.000000,315.000000 
	C1.000000,238.312485 1.000000,161.624893 1.374049,84.307686 
	C31.876587,79.789581 62.005074,75.901108 92.851128,71.920013 
	C92.851128,75.215523 92.851128,77.341110 92.851128,79.466698 
	C92.851128,155.623260 92.849403,231.779816 92.860588,307.936371 
	C92.860878,309.930023 93.001755,311.923676 93.061172,314.188232 
	C93.030182,314.639435 93.015091,314.819733 93.000000,315.000000 
z"/>
<path fill="#FFFFFF" opacity="0" stroke="none" 
	d="
M1551.000000,315.000000 
	C1503.645752,315.000000 1456.291504,315.000000 1408.395142,314.693909 
	C1407.808472,314.182739 1407.764038,313.977661 1407.777588,313.324158 
	C1407.835815,271.766968 1407.835815,230.658234 1407.835815,189.417267 
	C1455.886108,189.417267 1503.398438,189.417267 1551.132202,189.417267 
	C1551.132202,191.987946 1551.129883,194.148651 1551.132568,196.309357 
	C1551.181274,235.463058 1551.230713,274.616760 1551.213379,314.078583 
	C1551.097656,314.591156 1551.048828,314.795563 1551.000000,315.000000 
z"/>
<path fill="#B6855D" opacity="1.000000" stroke="none" 
	d="
M2029.468994,315.000000 
	C2026.813843,314.585663 2024.643921,314.029327 2022.439087,313.779633 
	C2005.420410,311.852600 1993.079590,302.947998 1986.124023,287.611237 
	C1980.925781,276.149597 1976.821777,264.132324 1973.015869,252.113770 
	C1968.431763,237.638077 1963.912720,223.212845 1955.285767,210.509415 
	C1944.118042,194.064804 1928.583740,185.479279 1908.682861,184.858231 
	C1906.686035,184.795914 1904.690552,184.685608 1902.695923,184.569946 
	C1902.380737,184.551682 1902.073975,184.386093 1901.381226,184.168777 
	C1901.381226,180.566223 1901.381226,176.833862 1901.381226,172.650467 
	C1907.026123,172.354568 1912.852295,172.505722 1918.534668,171.674362 
	C1947.072998,167.499115 1959.944946,153.076996 1960.106934,123.890533 
	C1960.154907,115.260056 1958.723755,106.161186 1955.782593,98.072411 
	C1950.078491,82.384888 1936.593384,75.960983 1921.039917,74.412842 
	C1910.678223,73.381454 1900.130859,74.217041 1889.055420,74.217041 
	C1889.055420,153.915848 1889.055420,233.785294 1889.055420,313.899414 
	C1878.856445,313.899414 1869.264526,313.899414 1859.334229,313.899414 
	C1859.334229,229.086914 1859.334229,144.390793 1859.334229,59.638760 
	C1859.935059,59.451298 1860.394287,59.178810 1860.850220,59.184280 
	C1882.327515,59.441811 1903.836548,59.098186 1925.271362,60.198772 
	C1942.646973,61.090946 1958.989502,66.256790 1972.593506,77.908058 
	C1988.400879,91.446342 1993.465576,109.178314 1991.492432,129.115662 
	C1988.842407,155.891556 1972.655640,171.386459 1947.919434,179.260300 
	C1948.018677,179.536774 1948.047241,179.934341 1948.195923,179.986038 
	C1973.538696,188.785324 1987.963989,208.095642 1997.404053,231.836945 
	C2001.572388,242.320602 2004.213501,253.405319 2008.230347,263.957367 
	C2011.353027,272.160339 2015.018799,280.254272 2019.365723,287.870148 
	C2025.635376,298.854889 2035.964966,304.279297 2048.607910,306.158142 
	C2049.000000,308.953888 2049.000000,311.907776 2049.000000,315.000000 
	C2042.646484,315.000000 2036.292358,315.000000 2029.468994,315.000000 
z"/>
<path fill="#B6865E" opacity="1.000000" stroke="none" 
	d="
M1226.437500,315.000000 
	C1221.163086,314.358276 1216.340698,313.563782 1211.486938,313.098114 
	C1154.662109,307.646606 1107.802002,265.274078 1098.540771,208.752197 
	C1086.663940,136.267975 1131.451050,78.640404 1195.460571,63.082058 
	C1222.968018,56.396000 1250.365356,59.120827 1277.622925,64.601685 
	C1287.830444,66.654190 1297.829834,69.741417 1308.108765,72.404694 
	C1307.307373,81.019897 1306.600586,88.618515 1305.779541,97.445465 
	C1303.054199,96.696854 1299.980591,96.725304 1298.190674,95.223656 
	C1283.084961,82.550812 1265.236328,76.729713 1246.162720,74.138206 
	C1224.577148,71.205437 1203.541870,73.392525 1183.915283,83.394203 
	C1155.763428,97.740349 1139.359741,121.540619 1132.424438,151.650269 
	C1124.953125,184.086472 1126.027954,216.414169 1141.390137,246.607025 
	C1160.206665,283.588806 1191.253540,301.036926 1232.713379,300.181610 
	C1258.860107,299.642181 1282.332764,291.689636 1303.111328,275.705780 
	C1304.853516,274.365631 1307.570435,274.292603 1310.885132,273.323730 
	C1310.019287,282.476471 1309.391357,290.509888 1308.353149,298.489929 
	C1308.207520,299.608551 1306.187134,301.014557 1304.803589,301.359070 
	C1290.154907,305.006714 1275.550659,308.939514 1260.742432,311.800323 
	C1251.816528,313.524719 1242.553101,313.501282 1233.220703,314.631653 
	C1230.958374,315.000000 1228.916626,315.000000 1226.437500,315.000000 
z"/>
<path fill="#B6865E" opacity="1.000000" stroke="none" 
	d="
M93.077072,313.917328 
	C93.001755,311.923676 92.860878,309.930023 92.860588,307.936371 
	C92.849403,231.779816 92.851128,155.623260 92.851128,79.466698 
	C92.851128,77.341110 92.851128,75.215523 92.851128,71.920013 
	C62.005074,75.901108 31.876587,79.789581 1.374049,83.839027 
	C1.000000,75.979103 1.000000,67.958199 1.000000,59.468651 
	C27.480896,59.043938 53.961781,59.113045 80.442688,59.126068 
	C123.081924,59.147041 165.721176,59.131004 208.360428,59.130604 
	C210.180908,59.130585 212.001373,59.130604 214.240860,59.130604 
	C214.240860,67.124130 214.240860,74.838379 214.240860,83.500351 
	C183.996902,79.723587 153.914276,75.966972 122.835007,72.085899 
	C122.835007,152.842743 122.835007,232.852783 122.335190,313.358612 
	C112.249275,313.875366 102.663177,313.896332 93.077072,313.917328 
z"/>
<path fill="#E0CAB9" opacity="1.000000" stroke="none" 
	d="
M1783.854126,313.864410 
	C1783.875000,314.054108 1783.895996,314.243805 1783.958496,314.716736 
	C1745.645752,315.000000 1707.291504,315.000000 1668.468628,315.000000 
	C1668.087524,314.775146 1668.174927,314.550293 1668.808960,314.076477 
	C1707.521729,313.839752 1745.687988,313.852081 1783.854126,313.864410 
z"/>
<path fill="#E0CAB9" opacity="1.000000" stroke="none" 
	d="
M93.061172,314.188232 
	C102.663177,313.896332 112.249275,313.875366 122.281479,313.808838 
	C122.773613,313.969299 122.819633,314.175293 122.932831,314.690613 
	C113.312439,315.000000 103.624878,315.000000 93.468658,315.000000 
	C93.015091,314.819733 93.030182,314.639435 93.061172,314.188232 
z"/>
<path fill="#E0CAB9" opacity="1.000000" stroke="none" 
	d="
M308.920837,313.916992 
	C308.931305,314.097687 308.941803,314.278412 308.976135,314.729553 
	C299.312439,315.000000 289.624878,315.000000 279.468658,315.000000 
	C279.049011,314.795227 279.097992,314.590454 279.659851,314.119720 
	C289.755432,313.874817 299.338135,313.895905 308.920837,313.916992 
z"/>
<path fill="#E0CAB9" opacity="1.000000" stroke="none" 
	d="
M1407.719482,313.772583 
	C1407.764038,313.977661 1407.808472,314.182739 1407.926514,314.693909 
	C1398.312378,315.000000 1388.624878,315.000000 1378.468628,315.000000 
	C1378.015625,314.819977 1378.031250,314.639923 1378.524780,314.192474 
	C1388.574951,313.874237 1398.147217,313.823395 1407.719482,313.772583 
z"/>
<path fill="#E0CAB9" opacity="1.000000" stroke="none" 
	d="
M1551.468628,315.000000 
	C1551.048828,314.795563 1551.097656,314.591156 1551.659180,314.121155 
	C1561.755249,313.876404 1571.338623,313.897217 1580.921997,313.918030 
	C1580.932129,314.098602 1580.942383,314.279144 1580.976318,314.729828 
	C1571.312378,315.000000 1561.624878,315.000000 1551.468628,315.000000 
z"/>
<path fill="#B6855E" opacity="1.000000" stroke="none" 
	d="
M1581.012451,313.462769 
	C1571.338623,313.897217 1561.755249,313.876404 1551.725952,313.812988 
	C1551.230713,274.616760 1551.181274,235.463058 1551.132568,196.309357 
	C1551.129883,194.148651 1551.132202,191.987946 1551.132202,189.417267 
	C1503.398438,189.417267 1455.886108,189.417267 1407.835815,189.417267 
	C1407.835815,230.658234 1407.835815,271.766968 1407.777588,313.324127 
	C1398.147217,313.823395 1388.574951,313.874237 1378.539795,313.921844 
	C1378.001343,312.258362 1377.859741,310.598145 1377.859497,308.937897 
	C1377.848755,227.615707 1377.850464,146.293518 1377.850586,64.971336 
	C1377.850586,63.189209 1377.850586,61.407082 1377.850586,59.389996 
	C1388.048584,59.389996 1397.628906,59.389996 1407.831787,59.389996 
	C1407.831787,97.659889 1407.831787,135.710846 1407.831787,174.048096 
	C1455.716187,174.048096 1502.979736,174.048096 1550.863403,174.048096 
	C1550.863403,136.013351 1550.863403,97.964546 1550.863403,59.526161 
	C1561.177612,59.526161 1570.878296,59.526161 1581.102905,59.526161 
	C1581.102905,144.132767 1581.102905,228.570129 1581.012451,313.462769 
z"/>
<path fill="#B6865F" opacity="1.000000" stroke="none" 
	d="
M1783.916016,313.408997 
	C1745.687988,313.852081 1707.521729,313.839752 1668.936279,313.737732 
	C1668.454834,311.819244 1668.338501,309.990417 1668.338257,308.161621 
	C1668.329224,228.357285 1668.330444,148.552963 1668.330566,68.748634 
	C1668.330566,65.781212 1668.330566,62.813786 1668.330566,59.490646 
	C1706.785889,59.490646 1744.491211,59.490646 1782.534302,59.490646 
	C1782.534302,67.156876 1782.534302,74.718048 1782.534302,82.804077 
	C1754.723389,79.231857 1727.125000,75.686943 1699.045410,72.080200 
	C1699.045410,106.695419 1699.045410,140.417542 1699.045410,174.886932 
	C1725.483276,173.298035 1751.795898,171.716660 1778.562744,170.107971 
	C1778.562744,177.515671 1778.562744,184.723419 1778.562744,192.601471 
	C1752.188110,191.031250 1725.750366,189.457260 1698.973389,187.863083 
	C1698.973389,225.743439 1698.973389,262.986359 1698.973389,301.399719 
	C1727.172485,297.809509 1755.244507,294.235474 1783.977905,290.577271 
	C1783.977905,298.394379 1783.977905,305.673981 1783.916016,313.408997 
z"/>
<path fill="#B6855D" opacity="1.000000" stroke="none" 
	d="
M971.882507,314.147217 
	C963.439453,314.147339 955.485046,314.147339 947.104919,314.147339 
	C947.104919,233.373123 947.104919,153.109680 947.104919,72.051270 
	C916.333801,75.898544 886.081299,79.680969 855.444580,83.511444 
	C855.444580,75.212662 855.444580,67.606888 855.444580,59.565922 
	C926.292969,59.565922 997.179504,59.565922 1068.468018,59.565922 
	C1068.468018,67.176224 1068.468018,74.889763 1068.468018,83.481789 
	C1038.034058,79.672256 1007.785095,75.885880 977.055420,72.039330 
	C977.055420,152.896439 977.055420,233.167725 977.055420,313.793579 
	C975.236694,313.930817 973.803894,314.038940 971.882507,314.147217 
z"/>
<path fill="#B6855D" opacity="1.000000" stroke="none" 
	d="
M309.011139,313.461761 
	C299.338135,313.895905 289.755432,313.874817 279.726929,313.811096 
	C279.232361,309.272461 279.141602,304.776550 279.141083,300.280609 
	C279.132355,221.974106 279.134735,143.667603 279.134674,65.361092 
	C279.134674,63.549030 279.134644,61.736969 279.134644,59.530785 
	C289.171539,59.530785 298.870453,59.530785 309.041779,59.530785 
	C309.041779,97.599197 309.041779,135.512344 309.041779,173.883087 
	C357.043549,173.883087 404.580780,173.883087 452.681274,173.883087 
	C452.681274,136.032684 452.681274,97.987808 452.681274,59.539871 
	C462.940582,59.539871 472.647919,59.539871 482.682434,59.539871 
	C482.682434,144.263489 482.682434,228.950729 482.682434,313.892792 
	C472.870667,313.892792 463.278961,313.892792 453.154663,313.892792 
	C453.154663,272.416809 453.154663,231.028015 453.154663,189.322784 
	C405.055023,189.322784 357.484589,189.322784 309.101440,189.322784 
	C309.101440,230.522812 309.101440,271.764679 309.011139,313.461761 
z"/>
<path fill="#FFFEFE" opacity="0" stroke="none" 
	d="
M648.205078,219.195480 
	C633.057495,236.298126 618.451904,253.479782 600.352112,267.075806 
	C593.190979,272.455017 585.536255,277.259979 577.744446,281.690002 
	C571.761719,285.091431 570.216187,283.859344 570.015564,276.982635 
	C569.254517,250.897202 576.674866,226.658356 587.234558,203.273026 
	C598.880005,177.483170 615.031128,155.150482 639.148071,139.447464 
	C656.157104,128.372528 674.817078,123.277130 695.129089,124.910851 
	C704.758789,125.685394 712.941467,129.869461 720.957031,137.063309 
	C696.702698,164.452667 672.577637,191.696091 648.205078,219.195480 
z"/>
<path fill="#FBF9F7" opacity="0" stroke="none" 
	d="
M771.879028,133.000015 
	C771.879028,146.225601 771.879028,158.951187 771.879028,172.149902 
	C765.383179,158.735672 759.195068,145.957016 752.642273,132.425278 
	C758.334229,125.327400 764.714539,117.371185 771.879089,108.436996 
	C771.879089,117.335777 771.879089,124.917908 771.879028,133.000015 
z"/>
    </svg>
  );
}

export default Logo;
