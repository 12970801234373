import { motion } from "framer-motion";
import styled from 'styled-components';
import BackButton from "../Components/BackButton";

export default function Projects() {
  return (
    <div>
      <BackButton>home</BackButton>
      <motion.main
        className="main__container"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ x: "100%", opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <ProjectStyles>
          <h1>Coming Soon!</h1>
          <p>Currently refreshing our content.</p>
        </ProjectStyles>
      </motion.main>
    </div>
  );
}

const ProjectStyles = styled.div`
  text-align: center;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 2;
  text-align: center;
  overflow: hidden;

  h1 {
    font-family: 'Work Sans', sans-serif;
    font-family: 'Cormorant Garamond', serif;
    text-align: center;
    width: 100%;
    font-family: 'Cormorant Garamond', serif;
    font-family: 'Work Sans', sans-serif;
  }
  p {
    font-size: 40px;
    margin-bottom: 20px;
    text-align: centre;
    font-family: 'Work Sans', sans-serif;
    font-family: 'Cormorant Garamond', serif;
    color: #767676;
    line-height: 1.375em;
  }
`;
