import { motion } from "framer-motion";
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import BackButton from "../Components/BackButton";

export default function About() {
  return (
    <div>
      <BackButton>home</BackButton>
      <motion.main
        className="main__container"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ x: "100%", opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <AboutStyles>
          <h1>We are a joinery business, partnering with designers and builders to create bespoke spaces</h1>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
              <Grid item xs={12} sm={12} md={6} lg={6}>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className="rightgrid">
                <div className="right">
                  <p>Thatcher excels in delivering interior fabrication solutions, blending a professional client service approach with a comprehensive skill set. We believe that passion, integrity, and precision are vital for successful projects, taking great pride in ensuring consistent quality and design excellence.</p>
                  <p>Working closely with Designers, Architects, and Builders, Thatcher brings visions to life. This results in spaces that not only look stunning but also function as intelligent solutions for their occupants. We believe that passion, integrity, and precision are vital for successful projects, taking great pride in ensuring consistent quality and design excellence.</p>
                  <p>With years of industry experience, we offer first class fabrication solutions for projects of all sizes. By collaborating with different trades and experts, Thatcher ensures projects stay on track and within budget. Our expertise allows us to work collaboratively, creating solutions that surpass expectations and deliver end results tailored to your needs.</p>
                </div>
              </Grid>
            </Grid>
          </Box>
          <div className="fullscreenBg"></div>
        </AboutStyles>
      </motion.main>
    </div>
  );
}

const AboutStyles = styled.div`
  text-align: center;
  width: 100%;
  padding: 0px 0;
  max-width: 1000px;

  h1 {
    font-family: 'Work Sans', sans-serif;
    font-family: 'Cormorant Garamond', serif;
    text-align: center;
    width: 100%;
    font-size: 40px;
    font-family: 'Cormorant Garamond', serif;
    font-family: 'Work Sans', sans-serif;
    padding: 0 13px;
    margin-top: 170px;
    margin-bottom: 40px;
  }

  p {
    margin-bottom: 20px;
    padding: 0 40px;
    text-align: left;
    font-family: 'Work Sans', sans-serif;
    font-family: 'Cormorant Garamond', serif;
    color: #767676;
    line-height: 1.375em;
  }

  .rightgrid {
    margin-bottom: 40px;
  }

  .rightgrid:before {

  }

  @media (min-width: 900px) {
    .rightgrid:before {
      content: "";
      background-color: #CCC;
      position: absolute;
      width: 1px;
      height: 410px;
      top: 310px;
      left: 50%;
      display: block;
    }
  }
`;
