import styled from 'styled-components';

const RadioButtons = (props) => {

  const handleChange = (e) => {
    props.setBudget(e.target.value);
  }

  return (
    <StyledRadioButtons>
      <input 
        type="radio" 
        id="Under2k" 
        name="budget" 
        value="Under 2k" 
        checked={props.selectedOption === "Under 2k"}
        onChange={handleChange} />
      <label htmlFor="Under2k">Under 2k</label>

      <input 
        type="radio" 
        id="2-5k" 
        name="budget" 
        value="2-5k"
        checked={props.selectedOption === "2-5k"}
        onChange={handleChange} />
      <label htmlFor="2-5k">2-5k</label>

      <input 
        type="radio" 
        id="5-10k" 
        name="budget" 
        value="5-10k"
        checked={props.selectedOption === "5-10k"}
        onChange={handleChange} />
      <label htmlFor="5-10k">5-10k</label> 

      <input 
        type="radio" 
        id="+10k" 
        name="budget" 
        value="+10k"
        checked={props.selectedOption === "+10k"}
        onChange={handleChange} />
      <label htmlFor="+10k">+10k</label> 

      <input 
        type="radio" 
        id="Help" 
        name="budget" 
        value="Help"
        checked={props.selectedOption === "Help"}
        onChange={handleChange} />
      <label htmlFor="Help">Help me estimate</label> 
    </StyledRadioButtons> 
  )
}

export default RadioButtons;

const StyledRadioButtons = styled.div`
  margin: 10px;
  width: 100%;
  text-align: center;

  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  label {
    cursor: pointer;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.56);
    margin: 10px 3px 0;
    padding: 15px 30px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    border-radius: 30px;

    @media (max-width: 576px) {
      margin: 10px 1px 0;
    }
  
    &:hover {
      background-color: #FFF;
    }
  }

  input[type="radio"]:checked + label {
    background-color: #F75A1B;
    color: #FFF;
  }
}
`;
