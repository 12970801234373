import styled from 'styled-components';

const Switch = (props) => {

  const handleCheckboxClicked = (e) => {
    console.log(e.target.checked)

  }

  return (
    <StyledSwitch>
      <input className="switch-button-checkbox" type="checkbox" onChange={props.onChange} checked={props.checked ? 1 : 0}></input>
      <label className="switch-button-label">
        <span className="switch-button-label-span">Send Brief</span>
      </label>
    </StyledSwitch>
  )
}

export default Switch;

const StyledSwitch = styled.div`
  background: #cccabe;
  border-radius: 30px;
  overflow: hidden;
  width: 300px;
  text-align: center;
  font-size: 17px;
  font-family: "Work Sans", sans-serif;
  font-family: 'Cormorant Garamond', serif;
  color: #000;
  margin: auto;
  position: relative;
  padding-right: 140px;
  position: relative;

  &:before {
    content: "Contact";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked + .switch-button-label:before {
      transform: translateX(150px);
      transition: transform 300ms linear;
    }

    & + .switch-button-label {
      position: relative;
      padding: 13px 0;
      display: block;
      user-select: none;
      pointer-events: none;

      &:before {
        content: "";
        background: rgb(227, 225, 215);;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 30px;
        transform: translateX(0);
        transition: transform 300ms;
      }

      .switch-button-label-span {
        position: relative;
      }
    }
  }
`;
