import styled from 'styled-components';

const Close = ({ setOpen }) => {

  const handleOnClick = () => {
    setOpen();
  }
  return (
    <StyledClose onClick={handleOnClick}>
      <div className="leftright"></div>
      <div className="rightleft"></div>
    </StyledClose>
  )
}

export default Close

const StyledClose = styled.div`
  position: absolute;
  top: 20px;
  right: 2rem;
  width: 50px;
  height: 50px;
  cursor: pointer;

  @media (max-width: 576px) {
    top: 2px;
    right 2px;
  }

  .leftright {
    height: 4px;
    width: 50px;
    position: absolute;
    margin-top: 24px;
    background-color: #b5845c;
    border-radius: 2px;
    transform: rotate(45deg);
    transition: all .3s ease-in;
  }

  .rightleft {
    height: 4px;
    width: 50px;
    position: absolute;
    margin-top: 24px;
    background-color: #b5845c;
    border-radius: 2px;
    transform: rotate(-45deg);
    transition: all .3s ease-in;
  }

  .close {
    margin: 60px 0 0 5px;
    position: absolute;
  }
  
  &:hover .leftright {
    transform: rotate(-45deg);
    background-color: #b5845c;
  }

  &:hover .rightleft {
    transform: rotate(45deg);
    background-color: #b5845c;
  }

  &:hover label {
    opacity: 1;
  }
`;
  