import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import Close from './Close';
import Switch from './Switch';
import TextField from './TextField';
import TextArea from './TextArea';
import Button from './Button';
import RadioButtons from './Radio';
import Checkbox from './Checkbox';

const ContactForm = (props) => {
  const {
    name,
    setName,
    email,
    setEmail,
    message,
    setMessage,
    submitting,
    setSubmitting,
    sent,
    setSent,
  } = props;
  const textAreaRef = useRef(null);

  const resizeTextArea = () => {
    textAreaRef.current.style.height = 'auto';
    const addPadding = textAreaRef.current.scrollHeight + 5;
    textAreaRef.current.style.height = addPadding + 'px';
  };

  useEffect(resizeTextArea, [message]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const sendEmail = (e) =>{
    e.persist();
    e.preventDefault();
    setSubmitting(true);

    const templateParams = {
      name,
      message,
      contact: email,
    };

    emailjs
      .send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, templateParams, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      })
      .then(
        () => {
          setSubmitting(false);
          setSent(true);
        },
        (error) => {
          console.log('error:', error)
          setSubmitting(false);
        },
      );
  }

  if (sent) {
    return (
      <div>
        <p>Thanks! We will be in contact shortly.</p>
      </div>
    )
  } else {
    return (
      <div>
        <StyledInputs>
          <div>
            <TextField
              value={name}
              placeholder={'Your Name'}
              type={'text'}
              onChange={handleNameChange} />
          </div>
          <div>
            <TextField
              value={email}
              placeholder={'Your Email or Contact Number'}
              type={'email'}
              onChange={handleEmailChange} />
          </div>
          <div className='fullWidth'>
            <TextArea
              placeholder={'Message'}
              id="about-text"
              onChange={handleMessageChange}
              ref={textAreaRef}
              rows={1}
              value={message}
            />
          </div>
        </StyledInputs>
        <StyledSubmitButton>
          <Button name={'Send Request'} disabled={submitting} sendEmail={sendEmail} />
        </StyledSubmitButton>
      </div>
    )
  }
}

const QuoteForm = (props) => {
  const {
    name,
    setName,
    email,
    setEmail,
    about,
    setAbout,
    pdfdrawings,
    setPdfdrawings,
    caddrawings,
    setCaddrawings,
    sketch,
    setSketch,
    threedmodel,
    setThreedmodel,
    sitephotos,
    setSitephotos,
    measurements,
    setMeasurements,
    refrence,
    setRefrence,
    moodboard,
    setMoodboard,
    budget,
    setBudget,
    submitting,
    setSubmitting,
    sent,
    setSent,
  } = props;
  const textAreaRef = useRef(null);

  const resizeTextArea = () => {
    textAreaRef.current.style.height = 'auto';
    const addPadding = textAreaRef.current.scrollHeight + 5;
    textAreaRef.current.style.height = addPadding + 'px';
  };

  useEffect(resizeTextArea, [about]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handleAboutChange = (e) => {
    setAbout(e.target.value);
  };

  const sendEmail = (e) =>{
    e.persist();
    e.preventDefault();
    setSubmitting(true);

    const templateParams = {
      name,
      message: about,
      contact: email,
    };

    emailjs
      .send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, templateParams, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      })
      .then(
        () => {
          setSubmitting(false);
          setSent(true);
        },
        (error) => {
          console.log('error:', error)
          setSubmitting(false);
        },
      );
  }

  if (sent) {
    return (
      <div>
        <p>Thanks! We will be in contact shortly.</p>
      </div>
    )
  } else {
    return (
      <StyledQuoteForm>
        <StyledInputs>
          <div>
            <TextField
              value={name}
              placeholder={'Your Name'}
              type={'text'}
              onChange={handleNameChange} />
          </div>
          <div>
            <TextField
              value={email}
              placeholder={'Your Email or Contact Number'}
              type={'email'}
              onChange={handleEmailChange} />
          </div>
          <div className='fullWidth'>
            <TextArea
              placeholder={'Tell us about your project'}
              id="about-text"
              onChange={handleAboutChange}
              ref={textAreaRef}
              rows={1}
              value={about}
            />
          </div>
        </StyledInputs>
        {/* <StyledDocuments>
          <h3>Documents I have</h3>
          <div>
            <Checkbox name='PDF Drawings' value={pdfdrawings} setValue={setPdfdrawings} />
            <Checkbox name='AutoCAD Drawings' value={caddrawings} setValue={setCaddrawings} />
            <Checkbox name='Sketch' value={sketch} setValue={setSketch} />
            <Checkbox name='3D Model' value={threedmodel} setValue={setThreedmodel} />
            <Checkbox name='Site Photos' value={sitephotos} setValue={setSitephotos} />
            <Checkbox name='Measurements' value={measurements} setValue={setMeasurements} />
            <Checkbox name='Refrence' value={refrence} setValue={setRefrence} />
            <Checkbox name='Moodboard' value={moodboard} setValue={setMoodboard} />
          </div>
        </StyledDocuments> */}
        {/* <StyledBudget>
          <h3>Budget (AUD)</h3>
          <RadioButtons selectedOption={budget} setBudget={setBudget} />
        </StyledBudget> */}
        <StyledSubmitButton>
          <Button name={'Send Request'} disabled={submitting} sendEmail={sendEmail} />
        </StyledSubmitButton>
      </StyledQuoteForm>
    )
  }
}

const ContactQuote = ({ open, setOpen }) => {
  const [switchValue, setSwitchValue] = useState('quote');
  const [switchChecked, setSwitchChecked] = useState('false');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [about, setAbout] = useState('');
  const [message, setMessage] = useState('');

  const [pdfdrawings, setPdfdrawings] = useState(false);
  const [caddrawings, setCaddrawings] = useState(false);
  const [sketch, setSketch] = useState(false);
  const [threedmodel, setThreedmodel] = useState(false);
  const [sitephotos, setSitephotos] = useState(false);
  const [measurements, setMeasurements] = useState(false);
  const [refrence, setRefrence] = useState(false);
  const [moodboard, setMoodboard] = useState(false);

  const [budget, setBudget] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [sent, setSent] = useState(false);

  const formData = {};

  const handleSwitch = (e) => {
    if (e.target.checked) {
      setSwitchValue('contact');
      setSwitchChecked(true);
    } else {
      setSwitchValue('quote');
      setSwitchChecked(false);
    }
  }

  const handleClose = () => {
    setSwitchValue('quote');
    setSwitchChecked(false);
    setName('');
    setEmail('');
    setAbout('');
    setMessage('');
    setPdfdrawings(false);
    setCaddrawings(false);
    setSketch(false);
    setThreedmodel(false);
    setSitephotos(false);
    setMeasurements(false);
    setRefrence(false);
    setMoodboard(false);
    setBudget('');
    setOpen(false);
    setSubmitting(false);
    setSent(false);
  }

  return (
    <StyledContactQuote open={open}>
      <Close setOpen={handleClose} />
      <h2>We are ready to help!</h2>
      {
        sent ? '' : <Switch checked={switchChecked ? 1 : 0} onChange={handleSwitch} />
      }
      {
        switchValue === 'quote'
        ?
          <QuoteForm
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            about={about}
            setAbout={setAbout}
            pdfdrawings={pdfdrawings}
            setPdfdrawings={setPdfdrawings}
            caddrawings={caddrawings}
            setCaddrawings={setCaddrawings}
            sketch={sketch}
            setSketch={setSketch}
            threedmodel={threedmodel}
            setThreedmodel={setThreedmodel}
            sitephotos={sitephotos}
            setSitephotos={setSitephotos}
            measurements={measurements}
            setMeasurements={setMeasurements}
            refrence={refrence}
            setRefrence={setRefrence}
            moodboard={moodboard}
            setMoodboard={setMoodboard}
            budget={budget}
            setBudget={setBudget}
            submitting={submitting}
            setSubmitting={setSubmitting}
            sent={sent}
            setSent={setSent} />
        :
          <ContactForm
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            message={message}
            setMessage={setMessage}
            submitting={submitting}
            setSubmitting={setSubmitting}
            sent={sent}
            setSent={setSent} />
      }
    </StyledContactQuote>
  )
}

export default ContactQuote;

const StyledContactQuote = styled.div`
  background: #e3e1d7;
  background: #FFF;
  color: #000;
  transform: ${({ open }) => open ? 'translateY(0)' : 'translateY(-100%)'};
  width: 100%;
  text-align: center;
  padding: 70px 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 101;
  min-height: 1000px;

  h2 {
    font-size: 2rem;
    margin-bottom: 50px;
    font-family: "Work Sans", sans-serif;
  }

  h3 {
    font-size: 1.3rem;
  }

  p {
    font-family: 'Work Sans', sans-serif;
  }
`;

const StyledQuoteForm = styled.div`
  max-width: 640px;
  margin: auto;
`;

const StyledInputs = styled.div`
  grid-row-gap: 70px;
  grid-column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  margin: 60px auto;
  max-width: 640px;

  div {
    @media (max-width: 960px) {
      grid-column-end: 3;
      grid-column-start: 1;
    }
  }

  .fullWidth {
    grid-column-end: 3;
    grid-column-start: 1;
  }
`;

const StyledDocuments = styled.div`
  margin: 50px 0;
  width: 100%;
  text-align: left;

  h3 {
    margin-bottom: 20px;
  }

  div {
    align-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @media (max-width: 576px) {
      flex-direction: column;
    }
  }

  label {
    margin-bottom: 20px;
  }
`;

const StyledBudget = styled.div`
  margin: 50px 0;
  width: 100%;
  text-align: left;
`;

const StyledSubmitButton = styled.div`
  margin-top: 70px;
`;
