import React from "react";

function ArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="6"
      viewBox="0 0 28 6"
    >
      <path d="M0 3L5 5.88675V0.113249L0 3ZM28 2.5L4.5 2.5V3.5L28 3.5V2.5Z"></path>
    </svg>
  );
}

export default ArrowIcon;
